import {
  Table,
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  Input,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { get } from "lodash";
import { getAllEmployee } from "../../Graphs/Employee/listEmployee";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { deleteUser } from "../../Graphs/User/deleteUser";
import { changeStatus } from "../../Graphs/Employee/changeStatus";
import { editEmployee } from "../../Graphs/Employee/editEmployee";
import { searchUser } from "../../Graphs/User/searchUser";
import { EditModal } from "./EditModal";
import { alertError } from "../../utils/alert";

var moment = require("moment");

const BASE_IMAGE_URL =
  "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_user_media/";

class UserRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      userPermission: {
        delete: false,
        edit: false,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedUsers = this.getLimitedUsers.bind(this);
  }

  async componentDidMount() {
    this.setState({
      data: this.props.data,
      isLoading: false,
      userSelectedData: null,
      userTrasactionData: null,
    });

    let permission = JSON.parse(this.props.loginUserData.access_permission);
    this.setState({
      userPermission: {
        edit:
          (permission &&
            permission["employee"] &&
            permission["employee"].edit) ||
          false,
        delete:
          (permission &&
            permission["employee"] &&
            permission["employee"].delete) ||
          false,
      },
    });
  }

  modalRef;
  transactionRef;
  deletePermission = false;
  editPermission = false;

  async getLimitedUsers(pagination) {
    const { searchText } = this.props;
    console.log("tempCounter && counter --> ", this.tempCounter, this.counter);
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText != "") {
          //get search text props from redux store
          this.listSearchUserData(start, end);
        } else {
          //call normal user Data
          this.listEmployeeData(start, end);
        }
      }
    );
  }

  listSearchUserData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListEmployeeData,
        setListEmployeeDataTotal,
        searchText,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let employeeData = await searchUser(authToken, searchText, start, end);

      if (employeeData.status == 200) {
        //set user Data
        await setListEmployeeData(employeeData.data);
        await setListEmployeeDataTotal(employeeData.total);

        this.setState({
          isLoading: false,
          data: employeeData.data,
        });
      } else if (employeeData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw employeeData && employeeData.message
          ? employeeData.message
          : "Error";
      }
    } catch (e) {
      alertError("Employee", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listEmployeeData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListEmployeeData,
        setListEmployeeDataTotal,
        loginUserData,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : ""; // adding city for sub admin employee
      let employeeData = await getAllEmployee(authToken, start, end, city);

      if (employeeData.status == 200) {
        //set user Data
        await setListEmployeeData(employeeData.data);
        await setListEmployeeDataTotal(employeeData.total);

        this.setState({
          isLoading: false,
          data: employeeData.data,
        });
      } else if (employeeData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw employeeData && employeeData.message
          ? employeeData.message
          : "Error";
      }
    } catch (e) {
      alertError("EMPLOYEE", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY");

    return newDate;
  }

  showModal(item) {
    this.setState(
      {
        userSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  showUserCoinstModal(item) {
    this.setState(
      {
        userSelectedDataForCoin: item,
        // isOpen: true
      },
      () => {
        this.modalUserCoinsRef.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      userSelectedData: null,
      userSelectedDataForCoin: null,
    });
  }

  showUserTransactions = (item) => {
    this.setState(
      {
        userTrasactionData: item,
      },
      () => {
        this.transactionRef.show();
      }
    );
  };

  handleDeleteTransactionModalclose() {
    this.setState({
      userTrasactionData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      width: 150,
      key: "name",
      render: (text, item, index) => {
        return (
          <div>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              {/* <Avatar icon="user" /> */}
              {/* <div style={{ float: "left" }}>
                <Avatar icon={<UserOutlined />} />
              </div> */}
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                <strong>
                  {" "}
                  <div>
                    {item.last_name
                      ? item.first_name
                        ? item.first_name + " " + item.last_name
                        : item.username
                      : item.first_name
                      ? item.first_name
                      : item.username}
                  </div>
                </strong>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      render: (text, item, index) => {
        return <div>{item.email ? item.email : "-"}</div>;
      },
    },
    {
      title: "Mobile",
      key: "mobile",
      render: (text, item, index) => {
        return <div>{item.phone ? item.phone : "-"}</div>;
      },
    },
    {
      title: "city",
      key: "city",
      render: (text, item, index) => {
        return <div>{item.user_city ? item.user_city.name : "-"}</div>;
      },
    },
    {
      title: "Role",
      key: "role",
      render: (text, item, index) => {
        return <div>{item.role != null ? item.role : "-"}</div>;
      },
    },
    {
      title: "Active Status",
      key: "isActive",
      render: (text, item, index) => {
        return (
          <div>
            {this.props.loginUserData.role == "Admin" ||
            this.state.userPermission.edit ? (
              item.is_active != null ? (
                <Switch
                  checked={item.is_active}
                  style={{
                    backgroundColor: item.is_active
                      ? this.props.primaryColor
                      : "#bababa",
                  }}
                  onChange={this.onChange.bind(this, item)}
                />
              ) : (
                "-"
              )
            ) : item.is_active ? (
              "Active"
            ) : (
              "In-active"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 130,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            {this.props.loginUserData.role == "Admin" ||
            this.state.userPermission.edit ? (
              <div mr="5px" style={{ float: "left" }}>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      console.log("isOpen value --- ", this.state.isOpen);
                      this.showModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}

            {/* {this.props.loginUserData.role != "Admin" ||
            this.state.userPermission.delete ? (
              <div
                ml="15px"
                mr="5px"
                style={{ float: "left", marginLeft: "10px" }}
              >
                <Tooltip placement="bottom" title="Delete">
                  <Popconfirm
                    onConfirm={this.deleteUser.bind(this, item)}
                    title="Are you sure you want to delete user？"
                  >
                    <Button shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            ) : null} */}
          </div>
        );
      },
    },
  ];

  async deleteUser(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
        setListEmployeeData,
        setListEmployeeDataTotal,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteUser(
        authToken,
        item.id,
        startIndex,
        limitNumber,
        searchText
      );

      if (deleteData.status == 200) {
        notification["success"]({
          message: "Delete User",
          description: "Successfully deleted",
        });
        await setListEmployeeData(deleteData.data);
        await setListEmployeeDataTotal(deleteData.total);
        //set user Data
        this.setState({
          isLoading: false,
          data: deleteData.data,
        });
      } else if (deleteData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw deleteData && deleteData.message ? deleteData.message : "Error";
      }
    } catch (e) {
      alertError("User", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      console.log("!!!!!!!!", checked);

      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
        loginUserData,
      } = this.props;
      const { startIndex, limitNumber } = this.state;
      this.setState({
        isLoading: true,
      });
      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";

      let statusData = await changeStatus(authToken, item.id, checked, city);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Change User Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText != "") {
          this.listSearchUserData(startIndex, limitNumber);
          return;
        }
        this.listEmployeeData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (err) {
      alertError("User", err);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editSubmit = async (userObject) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
        loginUserData,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";
      let body;
      if (city) {
        body = { ...userObject, city };
      } else {
        body = userObject;
      }
      let statusData = await editEmployee(
        authToken,
        body,
        userObject.employeeId
      );
      if (statusData.status == 200) {
        notification["success"]({
          message: "Edit User Status",
          description: "Successfully edited",
        });
        if (searchText != null && searchText != "") {
          this.listSearchUserData(startIndex, limitNumber);
          return;
        }
        this.listEmployeeData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("User", e);
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedUsers(pagination)
    );
  }

  render() {
    const { isLoading } = this.state;
    const { listEmployeeData, listEmployeeDataTotal, searchLoader, authToken } =
      this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <Table
          loading={this.state.isLoading}
          rowKey={(record) => record._id}
          columns={this.columns}
          size={"small"}
          // dataSource={this.state.data}
          dataSource={listEmployeeData}
          pagination={{
            total: listEmployeeDataTotal,
            showSizeChanger: true,
            pageSize: this.state.pagination.pageSize,
            current: this.state.pagination.current,
            pageSizeOptions: ["1", "10", "25", "50", "100"],
            onChange: (e) => {
              console.log("onChanges pagination");
              // e - 1
              //   ? (this.counter = this.tempCounter =
              //       (e - 1) * this.state.pagination.pageSize)
              //   : ((this.counter = 0), (this.tempCounter = 0));
              if (e - 1) {
                this.counter = this.tempCounter =
                  (e - 1) * this.state.pagination.pageSize;
                return;
              }

              this.counter = 0;
              this.tempCounter = 0;
            },
          }}
          onChange={this.handleTableChange}
        />

        {this.state.userSelectedData ? (
          <EditModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            userSelectedData={this.state.userSelectedData}
            onSubmit={this.editSubmit}
            loginUserData={this.props.loginUserData}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listEmployeeData: state.employee.listEmployeeData,
  listEmployeeDataTotal: state.employee.listEmployeeDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (employeeData) => {
      dispatch({
        type: "SET_EMPLOYEE_DATA",
        employeeData: employeeData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_EMPLOYEE_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListEmployeeData: (listEmployeeData) => {
      dispatch({
        type: "SET_LIST_EMPLOYEE_DATA",
        listEmployeeData: listEmployeeData,
      });
    },
    setListEmployeeDataTotal: (listEmployeeDataTotal) => {
      dispatch({
        type: "SET_LIST_EMPLOYEE_DATA_TOTAL",
        listEmployeeDataTotal: listEmployeeDataTotal,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserRecordsTable));

import { Button, DatePicker, notification, Table } from "antd";
import { Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { alertError } from "../../utils/alert";
import { connect } from "react-redux";
import { profitReportExpensesAPI } from "../../Graphs/Report/NetProfitExpensesReport";
import { formatePrice } from "../../utils/general";
import TableWrap from "../common/Table";
const { RangePicker } = DatePicker;

class NetProfitReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expensesList: {
        admin_profit: 0,
        expenses_amount: 0,
        net_profit: 0,
      },
      startDate: "",
      endDate: "",
      isLoading: false,
      isTableOpen: false,
    };
    this.netProfitReportList = this.netProfitReportList.bind(this);
  }

  resetExpensesReportTable = () => {
    this.setState({
      expensesList: "",
      isTableOpen: false,
    });
  };

  onChangeDate = (date) => {
    if (!date || !date.length) {
      this.resetExpensesReportTable();
      this.setState({
        startDate: null,
        endDate: null,
      });
    }
    this.setState({
      startDate: date[0].format("YYYY-MM-DD"),
      endDate: date[1].format("YYYY-MM-DD"),
    });
  };

  netProfitReportList = async () => {
    try {
      const { authToken, loginUserData } = this.props;
      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";


      let payload = {
        start_date: this.state.startDate,
        end_date: this.state.endDate,
        city: city
      };

      let profitReportList = await profitReportExpensesAPI(authToken, payload);
      this.setState({
        isLoading: true,
      });
      if (profitReportList.status == 200) {
        this.setState({
          expensesList: {
            admin_profit: profitReportList.admin_profit,
            expenses_amount: profitReportList.expenses_amount,
            net_profit: profitReportList.net_profit,
          },
          isLoading: false,
          isTableOpen: true,
        });
      } else {
        throw profitReportList && profitReportList.message
          ? profitReportList.message
          : "Error";
      }
    } catch (e) {
      alertError("REPORT", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  columns = [
    {
      title: "",
      dataIndex: "name",
    },
    {
      title: "",
      dataIndex: "amount",
    },
  ];

  render() {
    const { expensesList,isTableOpen } = this.state;

    const data = [
      { 
        name: "Admin Profit",
        amount: `${formatePrice(expensesList?.admin_profit ?? 0)} LYD`,
      },
      {
        name: "Expenses Amount",
        amount: `${formatePrice(expensesList?.expenses_amount ?? 0)} LYD`,
      },
      {
        name: "Net Profit",
        amount: `${formatePrice(expensesList?.net_profit ?? 0)} LYD`,
      },
    ];

    return (
      <>
        <Formik
          initialValues={{
            date: "",
          }}
          onSubmit={() => {
            this.netProfitReportList();
          }}
        >
          {({ values, handleSubmit }) => {
            return (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  width: "100%",
                  marginBottom: "20px",
                  paddingRight: "55px",
                  justifyContent: "left",
                }}
              >
                <div style={{ cursor: "pointer" }}>
                  <RangePicker
                    format="YYYY-MM-DD"
                    onChange={this.onChangeDate}
                    disabledDate={(current) => {
                      return moment().add(0, "days") <= current;
                    }}
                  />
                </div>

                <div style={{ cursor: "pointer", width: "100%" }}>
                  <Button
                    style={{ background: "#5C6AC4", color: "#fff" }}
                    color={this.state.primaryColor}
                    onClick={handleSubmit}
                    disabled={this.state.startDate == ""}
                  >
                    Filter
                  </Button>
                </div>
              </div>
            );
          }}
        </Formik>
        {isTableOpen && <TableWrap style={{width:"600px"}} columns={this.columns} data={data} />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

export default connect(mapStateToProps)(withRouter(NetProfitReport));
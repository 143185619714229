import React, { useState, Fragment, useEffect } from "react";
import logo from "../../../assets/images/endless-logo.png";
import Language from "./language";
import UserMenu from "./userMenu";
import Notification from "./notification";
import SearchHeader from "./searchHeader";
import { Link } from "react-router-dom";
import {
  AlignLeft,
  Maximize,
  Bell,
  MessageCircle,
  MoreHorizontal,
  Search,
} from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { searchUser } from "../../../Graphs/User/searchUser";
import { searchCountry } from "../../../Graphs/Master/Country/searchCountry";
import { searchCategory } from "../../../Graphs/Master/Category/searchCategory";
import { searchType } from "../../../Graphs/Master/Type/searchType";
import { searchCard } from "../../../Graphs/Card/searchCard";
import { getAllTransactionsFilter } from "../../../Graphs/User/listTransactionListFilter";
import { getAllWalletTransactionsFilter } from "../../../Graphs/User/listWalletTransactionListFilter";
import { searchCoin } from "../../../Graphs/Master/Coin/searchCoin";
import { searchCurrency } from "../../../Graphs/Master/Currency/searchCurrency";
import { searchEmployee } from "../../../Graphs/Employee/searchEmployee";
import { searchCompany } from "../../../Graphs/Company/searchCompany";
import {
  getAllSearchCompanyCoinTrn,
  getAllSearchCompanyCoinPayTrn,
} from "../../../Graphs/CompanyTrn/listCompanyTrn";
import { searchCompanyTransaction } from "../../../Graphs/CompanyTrn/searchCompanyTrn";
import { getSalesUserList } from "../../../Graphs/sales/getSalesUserList";
import { getExpensesListAPI } from "../../../Graphs/expenses/GetExpensesList";
import { getAllSubAdmin } from "../../../Graphs/SubAdmin/listSubAdmin";

const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [headerbar, setHeaderbar] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const primaryColor = localStorage.getItem("primary_color");

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.remove("open");
      document.querySelector(".page-sidebar").classList.remove("open");
    } else {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.add("open");
      document.querySelector(".page-sidebar").classList.add("open");
    }
  };

  function showRightSidebar() {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".right-sidebar").classList.add("show");
    } else {
      setRightSidebar(!rightSidebar);
      document.querySelector(".right-sidebar").classList.remove("show");
    }
  }

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const dispatch = useDispatch();
  const searchText = useSelector((content) => content.auth.searchText);
  const routeName = useSelector((content) => content.auth.routeName);
  const authToken = useSelector((content) => content.auth.authToken);
  const loginUserData = useSelector((content) => content.auth.loginUserData);
  
  useEffect(() => {
    setSearchValue("");
  }, [routeName]);

  async function handleSearchKeyword(textValue) {
    setSearchValue(textValue);
    dispatch({
      type: "SET_SEARCH_TEXT",
      searchText: textValue,
    });
    if (routeName === "USER") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchUserData(textValue);
    } else if (routeName === "COUNTRY") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchCountryData(textValue);
    } else if (routeName === "CATEGORY") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchCategoryData(textValue);
    } else if (routeName === "TYPE") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchTypeData(textValue);
    } else if (routeName === "CARD") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchCardData(textValue);
    } else if (routeName === "TRANSACTION_TAB") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchTransactionFilterData(textValue);
    } else if (routeName === "WALLET_TRANSACTION_TAB") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchWalletTransactionFilterData(textValue);
    } else if (routeName === "COIN") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchCoinData(textValue);
    } else if (routeName === "CURRENCY") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchCurrencyData(textValue);
    } else if (routeName === "EMPLOYEE") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearcheEmployeeData(textValue);
    } else if (routeName === "SUBADMIN") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearcheSubAdminData(textValue);
    } else if (routeName === "COMPANY") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchCompanyData(textValue);
    } else if (routeName === "COMPANY_TRANSACTION") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchCompanyTrnsData(textValue);
    } else if (routeName === "COMPANY_COIN_TRANSACTION") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchCompanyCoinTrnsData(textValue);
    } else if (routeName === "COMPANY_COIN_PAY_TRANSACTION") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await listSearchCompanyCoinPayTrnsData(textValue);
    } else if (routeName === "SALES") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await getSaleslist(textValue);
    } else if (routeName === "EXPENSES") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await getExpensesList(textValue);
    } else if (routeName === "INVENTORY") {
      dispatch({
        type: "SET_SEARCH_LOADER",
        searchLoader: true,
      });
      await getExpensesList(textValue);
    }
  }

  async function listSearchCompanyCoinPayTrnsData(textValue) {
    let searchCompanyCoinData = await getAllSearchCompanyCoinPayTrn(
      authToken,
      loginUserData.id,
      0,
      10,
      textValue
    );
   

    if (searchCompanyCoinData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_COMPANY_TRN_DATA",
        listCompanyTrnData: searchCompanyCoinData.data,
      });
      await dispatch({
        type: "SET_LIST_COMPANY_TRN_DATA_TOTAL",
        listCompanyTrnDataTotal: searchCompanyCoinData.total,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchCompanyCoinTrnsData(textValue) {
    let searchCompanyCoinData = await getAllSearchCompanyCoinTrn(
      authToken,
      loginUserData.id,
      0,
      10,
      textValue
    );
  

    if (searchCompanyCoinData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_COMPANY_TRN_DATA",
        listCompanyTrnData: searchCompanyCoinData.data,
      });
      await dispatch({
        type: "SET_LIST_COMPANY_TRN_DATA_TOTAL",
        listCompanyTrnDataTotal: searchCompanyCoinData.total,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchCompanyTrnsData(textValue) {
    let searchCompanyTrnsData = await searchCompanyTransaction(
      authToken,
      textValue,
      0,
      10,
      loginUserData.id
    );
    console.log(
      "!!!!!!!!!!searchCompany after search ",
      searchCompanyTrnsData.total
    );

    if (searchCompanyTrnsData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_COMPANY_TRN_DATA",
        listCompanyTrnData: searchCompanyTrnsData.data,
      });
      await dispatch({
        type: "SET_LIST_COMPANY_TRN_DATA_TOTAL",
        listCompanyTrnDataTotal: searchCompanyTrnsData.total,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchCompanyData(textValue) {
    let searchCompanyData = await searchCompany(authToken, textValue, 0, 10);
    console.log(
      "!!!!!!!!!!searchCompany after search ",
      searchCompanyData.total
    );

    if (searchCompanyData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_COMPANY_DATA",
        listCompanyData: searchCompanyData.data,
      });
      await dispatch({
        type: "SET_LIST_COMPANY_DATA_TOTAL",
        listCompanyDataTotal: searchCompanyData.total,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearcheEmployeeData(textValue) {
    let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";
    let searchEmployeeData = await searchEmployee(authToken, textValue, 0, 10,city);
    if (searchEmployeeData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_EMPLOYEE_DATA",
        listEmployeeData: searchEmployeeData.data,
      });
      await dispatch({
        type: "SET_LIST_EMPLOYEE_DATA_TOTAL",
        listEmployeeDataTotal: searchEmployeeData.total,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearcheSubAdminData(textValue) {
    let searchSubAdminData = await getAllSubAdmin(authToken, 0, 10, textValue);
  
    if (searchSubAdminData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_SUB_ADMIN_DATA",
        listSubAdminData: searchSubAdminData.data,
      });
      await dispatch({
        type: "SET_LIST_SUB_ADMIN_DATA_TOTAL",
        listSubAdminDataTotal: searchSubAdminData.total,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchCurrencyData(textValue) {
    let searchCurrencyData = await searchCurrency(authToken, textValue, 0, 10);

    if (searchCurrencyData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_CURRENCY_DATA",
        listCurrencyData: searchCurrencyData.data,
      });
      await dispatch({
        type: "SET_LIST_CURRENCY_DATA_TOTAL",
        listCurrencyDataTotal: searchCurrencyData.total,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchCoinData(textValue) {
    let searchCoinData = await searchCoin(authToken, textValue, 0, 10);

    if (searchCoinData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_COIN_DATA",
        listCoinData: searchCoinData.data,
      });
      await dispatch({
        type: "SET_LIST_COIN_DATA_TOTAL",
        listCoinDataTotal: searchCoinData.total,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchWalletTransactionFilterData(textValue) {
    let walletTransactionData = await getAllWalletTransactionsFilter(
      authToken,
      textValue,
      0,
      10
    );
  

    if (walletTransactionData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_TRANSACTION_DATA",
        listTransactionData: walletTransactionData.data,
      });
      await dispatch({
        type: "SET_LIST_TRANSACTION_DATA_TOTAL",
        listTransactionDataTotal: walletTransactionData.total,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchTransactionFilterData(textValue) {
    let searchtransactionData = await getAllTransactionsFilter(
      authToken,
      textValue,
      0,
      10
    );
    console.log(
      "!!!!!!!!!!searchtransactionData after search ",
      searchtransactionData.total
    );

    if (searchtransactionData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_TRANSACTION_DATA",
        listTransactionData: searchtransactionData.data,
      });
      await dispatch({
        type: "SET_LIST_TRANSACTION_DATA_TOTAL",
        listTransactionDataTotal: searchtransactionData.total,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchCardData(textValue) {
    let city = loginUserData.role === "Sub_admin" ? loginUserData.city : "";

    let searchCardData = await searchCard(authToken, textValue, 0, 10,city);

    if (searchCardData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_CARD_DATA",
        listCardData: searchCardData.data,
      });
      await dispatch({
        type: "SET_LIST_CARD_DATA_TOTAL",
        listCardDataTotal: searchCardData.total,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchTypeData(textValue) {
    let searchTypeData = await searchType(authToken, textValue, 0, 10);
    console.log("!!!!!!!!!!searchTypeData after search ", searchTypeData.total);

    if (searchTypeData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_TYPE_DATA",
        listTypeData: searchTypeData.data,
      });
      await dispatch({
        type: "SET_LIST_TYPE_DATA_TOTAL",
        listTypeDataTotal: searchTypeData.total,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchCategoryData(textValue) {
    let categoryData = await searchCategory(authToken, textValue, 0, 10);

    if (categoryData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_CATEGORY_DATA",
        listCategoryData: categoryData.data,
      });
      await dispatch({
        type: "SET_LIST_CATEGORY_DATA_TOTAL",
        listCategoryDataTotal: categoryData.total,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchCountryData(textValue) {
    let countryData = await searchCountry(authToken, textValue, 0, 10);
    if (countryData.status === 200) {
      //set user data
      await dispatch({
        type: "SET_LIST_COUNTRY_DATA",
        listCountryData: countryData.data,
      });
      await dispatch({
        type: "SET_LIST_COUNTRY_DATA_TOTAL",
        listCountryDataTotal: countryData.total,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function listSearchUserData(textValue) {
    let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";

    let userData = await searchUser(authToken, textValue, 0, 10,city);

    if (userData.status == 200) {
      await dispatch({
        type: "SET_LIST_USER_DATA",
        listUserData: userData.data,
      });
      await dispatch({
        type: "SET_LIST_USER_DATA_TOTAL",
        listUserDataTotal: userData.total,
      });
    }
    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function getSaleslist(textValue) {
    let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";

    let salesData = await getSalesUserList(authToken, 0, 10, city, textValue);

    if (salesData.status === 200) {
      await dispatch({
        type: "SET_SALES_DATA",
        salesData: salesData.data,
      });
      await dispatch({
        type: "SET_SALES_DATA_TOTAL",
        totalSales: salesData.total_user,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  async function getExpensesList(textValue) {
    let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";

    let expensesData = await getExpensesListAPI(authToken, 0, 10, textValue,city);

    if (expensesData.status === 200) {
      await dispatch({
        type: "SET_EXPENSES_DATA",
        expensesData: expensesData.data,
      });
      await dispatch({
        type: "SET__DATA_TOTAL",
        totalExpenses: expensesData.total_user,
      });
    }

    dispatch({
      type: "SET_SEARCH_LOADER",
      searchLoader: false,
    });
  }

  return (
    <Fragment>
      <div className="page-main-header">
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none">
            <div className="logo-wrapper">
              <Link to="/dashboard/default">
                {/* <img className="img-fluid" src={logo} alt="" /> */}
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "0px",
                    fontSize: "25px",
                    fontWeight: "bold",
                  }}
                >
                  POINT
                </div>
              </Link>
            </div>
          </div>
          <div className="mobile-sidebar d-block">
            <div className="media-body text-right switch-sm">
              <label className="switch">
                <a href="#javascript" onClick={() => openCloseSidebar()}>
                  <AlignLeft />
                </a>
              </label>
            </div>
          </div>
          <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? "" : "open"}`}>
              <li>
                <Fragment>
                  <div>
                    <form className="form-inline search-form">
                      {routeName !== "BALANCE" &&
                      routeName !== "QUESTION" &&
                      routeName !== "SETTING" &&
                      routeName !== "DASHBOARD" &&
                      routeName !== "TICKET-SUPPORT" &&
                      routeName !== "REPORT" &&
                      routeName !== "BALANCE-REQUEST" &&
                      routeName !== "FREEZE-USER" &&
                      routeName !== "CITY" ? (
                        <div className="form-group">
                          <input
                            className="form-control-plaintext searchIcon"
                            type="text"
                            placeholder={
                              routeName === "USER"
                                ? "Search User"
                                : routeName === "COUNTRY"
                                ? "Search Country"
                                : routeName === "CATEGORY"
                                ? "Search Category"
                                : routeName === "TYPE"
                                ? "Search Type"
                                : routeName === "CARD"
                                ? "Search Card"
                                : routeName === "TRANSACTION_TAB"
                                ? "Search Transaction using orderCode"
                                : routeName === "WALLET_TRANSACTION_TAB"
                                ? "Search Wallet Transaction using orderCode"
                                : routeName === "COIN"
                                ? "Search Coin"
                                : routeName === "CURRENCY"
                                ? "Search Currency"
                                : routeName === "EMPLOYEE"
                                ? "Search Employee"
                                : routeName === "COMPANY"
                                ? "Search Company"
                                : routeName === "COMPANY_TRANSACTION"
                                ? "Search Company transaction"
                                : routeName === "COMPANY_COIN_TRANSACTION"
                                ? "Search Company Coin transaction"
                                : routeName === "COMPANY_COIN_PAY_TRANSACTION"
                                ? "Search Company Coin Paid transaction"
                                : routeName === "DISTRIBUTOR"
                                ? "Search Distributor"
                                : routeName === "SALES"
                                ? "Search Sales"
                                : routeName === "EXPENSES"
                                ? "Search Expenses"
                                : routeName === "INVENTORY"
                                ? "Search Inventory"
                                : routeName === "BALANCE-REQUEST"
                                ? "Search Balance request"
                                : "Search"
                            }
                            value={searchValue}
                            onChange={(e) =>
                              handleSearchKeyword(e.target.value)
                            }
                          />
                          <span className="d-sm-none mobile-search">
                            <Search />
                          </span>
                        </div>
                      ) : null}
                    </form>
                  </div>
                </Fragment>
              </li>
              {/* <li>
                <SearchHeader />
              </li> */}
              {/* <li>
                <a onClick={goFull} className="text-dark" href="#!">
                  <Maximize />
                </a>
              </li> */}
              {/* <li className="onhover-dropdown">
                <a className="txt-dark" href="#javascript">
                  <h6>EN</h6></a>
                <Language />
              </li>
              <li className="onhover-dropdown">
                <Notification />
                <Bell />
                <span className="dot"></span>
                <Notification />
              </li> */}
              <li style={{ marginRight: "0px" }}>
                {/* <a href="#javascript" onClick={showRightSidebar}>
                  <MessageCircle />
                  <span className="dot"></span>
                </a> */}
                <a onClick={goFull} className="text-dark" href="#!">
                  <Maximize />
                </a>
              </li>
              <UserMenu />
            </ul>
            <div
              className="d-lg-none mobile-toggle pull-right"
              onClick={() => setHeaderbar(!headerbar)}
            >
              <MoreHorizontal />
            </div>
          </div>
          <script id="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-airplay m-0"
                >
                  <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName"></div>
              </div>
            </div>
          </script>
          <script id="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">
              Your search turned up 0 results. This most likely means the
              backend is down, yikes!
            </div>
          </script>
        </div>
      </div>
    </Fragment>
  );
};
export default Header;
